import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ArticleHTML from '../util/ArticleHTML'
import ProductAds from './ProductAds'
import CategoryList from '../lists/CategoryList'
import Image from '../util/Image'
import ShareButtons from '../widgets/ShareButtons'
import ArticleAuthorList from './ArticleAuthorList'
import Comparisons from './Comparisons'
import SideStories from './SideStories'
import Paywall from '../../widgets/Paywall'
import WeightTable from './WeightTable'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'
import MostReadPaid from '../../widgets/MostReadPaid'
import RelatedArticleList from '../article/RelatedArticleList'
import ImageCollage from './ImageCollage'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { SubscriberIcon } from '../../widgets/Icons'
import { connect } from 'kea'
import some from 'lodash/some'
import KeywordList from '../lists/KeywordList'
import NettixEmbed from '../nettix/NettixEmbed'
import BestProducts from '../../widgets/BestProducts'
import Timeline from '../../widgets/Timeline'
import ArticleComments from '../comments/ArticleComments'
import ReadMore from '../../widgets/ReadMore'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import Sidebar from '../../views/Sidebar'
import { AdsForDesktop, AdsForMobile, AdSlotMob2, AdSlotRectangle1, AdSlotSkyscraper1 } from '../ads/Ads'
import EditorsPicks from '../editorsPicks/EditorsPicks'
import CrossLinkBox from '../../widgets/CrossLinkBox'
import NoAdBlocker from '../../widgets/NoAdBlocker'
import Newsletter from '../newsletter/Newsletter'
import readPeakLogic from '@otavamedia/om-component-library/lib/kea/readPeak'
import LatestPosts from '../popular/LatestPosts'
import articleTypes from './ArticleTypes'
import MagShortCut from '../../widgets/MagShortCut'
import ArticleMetaDivider from './ArticleMetaDivider'
import ArticleDate from './ArticleDate'
import WhyOrder from '../../widgets/WhyOrder'
import { breakpoints } from '@otavamedia/om-component-library/lib/lib/utils'
import GAMNativeAdHolder from '../../widgets/GAMNativeAdHolder'
import RandomArticle from '../../widgets/RandomArticle'
import Themebox from './Themebox'
import CrossLinkTopNotification from '../../widgets/CrossLinkTopNotification'
import CrossLinkBottomNotification from '../../widgets/CrossLinkBottomNotification'
import PaywallBanner from '../../widgets/PaywallBanner'
import AuthorWidget from '../../widgets/AuthorWidget'
import ReportError from '../widgets/ReportError'

import '../../../containers/Article.scss'

@connect({
  props: [
    auth, [
      'premiumUser',
    ],
    readPeakLogic, ['getReadPeakAds'],
  ]
})
class FlowArticle extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    pagination: PropTypes.object,
    payWall: PropTypes.bool,
    noManualComparisonPlacement: PropTypes.bool,
    premiumUser: PropTypes.bool,
    getReadPeakAds: PropTypes.array,
    history: PropTypes.object,
    doneLoading: PropTypes.func,
  }

  state = {
    nativeAds: [],
    readPeakAds: [],
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.state.readPeakAds.length && nextProps.getReadPeakAds.length) {
      const ads = nextProps.getReadPeakAds.splice(0, (nextProps.getReadPeakAds.length > 3) ? 3 : nextProps.getReadPeakAds.length)
      nextProps.getReadPeakAds.push(...ads)
      this.setState({
        readPeakAds: ads
      })
    }
  }

  render () {
    const { article, payWall, noManualComparisonPlacement, premiumUser } = this.props
    const {
      id,
      content,
      title,
      author,
      categories,
      ingress,
      photoCaption,
      photographer,
      photoCopyright,
      shareCount,
      pagination,
      assistants,
      comparisons,
      weightTable,
      sidestories,
      linkedProductCards,
      linkedProductMemories,
      linkedQuestions,
      createToc,
      relatedArticles,
      productAds,
      relatedAds,
      hideAds,
      disableAdCoin,
      imageCollage,
      tags,
      nettixSearchId,
      timelineTag,
      isAd,
      link,
      articleType,
      commentator,
      headerVideoMp4,
      headerVideoWebm,
      authorOverride,
      originalArticle,
      createdDate
    } = article
    let { featuredMedia } = article
    if (featuredMedia && featuredMedia.src && featuredMedia.src.includes(window.om_constants.defaultImage)) {
      featuredMedia = null
    }

    const textStyles = `article-text ${payWall ? 'paywall-fade' : ''}`
    const isResearcher = some(categories, (cat) => cat.slug === 'tutkijalta')
    const isBest = some(categories, { slug: 'testivoittaja' })
    const isTest = some(tags, { slug: 'testipankki' })
    const showAds = !hideAds
    const isComment = articleType === articleTypes.COMMENT
    const video = headerVideoMp4 || headerVideoWebm
    return (
      <Fragment>
        <article styleName={'article' + (isComment ? ' comment' : '')}>
          <header>
            <div styleName="article-meta">
              <CategoryList categories={categories} mainOnly/>
              <ArticleMetaDivider/>
              <ArticleDate date={createdDate} dateOnly/>
            </div>
            <h1><HTML>{title}</HTML></h1>
            {isComment
              ? <div styleName="comment-author">
                {featuredMedia && <div>
                  <div styleName="featured-image">
                    <Image data={featuredMedia} size={IMAGE_SIZE.MEDIUM_LARGE}
                      sizes={'(min-width: 576px) 250px, 100vw'}/>
                  </div>
                </div>
                }
                <div styleName="comment-author-description">
                  <ArticleAuthorList author={authorOverride || commentator}/>
                </div>
                <div styleName="share-buttons">
                  <ShareButtons shareCount={shareCount} article={article}/>
                </div>
              </div>
              : <Fragment>
                <div styleName="ingress">{isResearcher &&
                <span styleName="researcher">Tutkijalta</span>}<HTML>{ingress}</HTML></div>
                {article.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon/></div>}
                {video
                  ? <div styleName="featured-image" dangerouslySetInnerHTML={{
                    __html: `
                      <video
                        loop
                        muted
                        autoplay
                        playsinline
                        src="${video.url}"
                      />,
                    `
                  }}>
                  </div>
                  : featuredMedia && <figure>
                    <div styleName="featured-image">
                      <Image addToGallery data={featuredMedia} noHeight/>
                    </div>
                    {(photoCaption || photoCopyright) && <div styleName="featured-image-caption-box">
                      <figcaption><HTML>{photoCaption}</HTML></figcaption>
                      <div styleName="image-copyright"><HTML>{photoCopyright}</HTML></div>
                    </div>}
                  </figure>
                }
                <div styleName="article-meta-row">
                  <div styleName="article-author">
                    <ArticleAuthorList author={author} photographer={photographer} assistants={assistants}/>
                  </div>
                  <div styleName="meta-row-share-buttons">
                    <ShareButtons shareCount={shareCount} article={article}/>
                  </div>
                </div>
                {originalArticle &&
                  <CrossLinkTopNotification text={originalArticle.topMessage} />
                }
                <div styleName="full-width-col">
                  <PaywallBanner/>
                </div>
              </Fragment>}
          </header>
          {comparisons && comparisons.length === 1
            ? (
              <div styleName="comparison-container">
                <Comparisons data={comparisons}/>
              </div>
            )
            : null
          }
          <div styleName={`${textStyles}`} className={'article-body ' + (premiumUser ? 'user-level-4' : '') + (hideAds ? ' noAds' : '')}>
            {payWall
              ? <ArticleHTML options={{ id, pagination, createToc, filterDivs: true }}>{content}</ArticleHTML>
              : <ArticleHTML options={{ linkedProductCards, linkedQuestions, linkedProductMemories, id, pagination, createToc }}>{content}</ArticleHTML>
            }
          </div>
            {imageCollage && !payWall
              ? <div>
                <ImageCollage count={imageCollage.length} images={imageCollage}/>
              </div>
              : null}
                                {noManualComparisonPlacement && comparisons && comparisons.length > 1
              ? (
                <div styleName="comparison-container">
                  <Comparisons data={comparisons}/>
                </div>
              )
              : null
            }
            {weightTable ? weightTable.map((table, idx) => <WeightTable key={idx} data={table}/>) : null}
            {!payWall ? <SideStories sidestories={sidestories}/> : null}
            {payWall ? <Paywall disableAdCoin={disableAdCoin}/> : null}
            <AuthorWidget author={isComment ? commentator : author} />
            {(productAds || relatedAds) && <ProductAds ads={productAds} related={relatedAds} />}
            {article.forSubscribers && !premiumUser ? <div><MostReadPaid exclude={[id]}/></div> : null}
          </article>
        {originalArticle &&
          <CrossLinkBottomNotification title={originalArticle.title} text={originalArticle.siteDescription} linkText={originalArticle.linkText} href={originalArticle.url} />
        }
        {article.linkedThemeBoxesData && article.linkedThemeBoxesData.map((tbData, key) => <Themebox key={key} data={tbData}></Themebox>)}
        <div styleName="bottom-border meta-row-share-buttons-bottom">
          <ShareButtons shareCount={shareCount} article={article} up/>
          <ReportError article={article}/>
        </div>
        <div styleName="full-width-col">
          <PaywallBanner showText={true}/>
        </div>
        <div>
          <div styleName="keywords">
            {tags && tags.length
              ? <Fragment>
                <span styleName="title">Lisää aiheesta</span>
                <KeywordList keywords={tags}/>
              </Fragment>
              : null}
          </div>
        </div>
        {relatedArticles && relatedArticles.length > 0
            ? (
              <RelatedArticleList related={relatedArticles} limit={4}/>
            )
            : null}
        {nettixSearchId
            ? (
              <div styleName="nettix-embed-container">
                <NettixEmbed searchId={nettixSearchId}/>
              </div>
            )
            : null}
          {isBest || isTest ? <div styleName="margins"><BestProducts isBest={isBest}/></div> : null}
          <div>
            {timelineTag ? <Timeline exclude={[article.id]} term={timelineTag}/> : null}
          </div>
          {isAd
            ? null
            : <div styleName="comment-container">
              <ArticleComments articleId={id}/>
            </div>}
         {showAds && <Breakpoint maxBreakpoint={767}>
          <div styleName="native-ads">
            <GAMNativeAdHolder/>
          </div>
        </Breakpoint>}
        {showAds && <Breakpoint minBreakpoint={767}>
          <div styleName="native-ads">
            <GAMNativeAdHolder/>
          </div>
        </Breakpoint>}
        <ReadMore excluded={[link]}/>
        <Breakpoint maxBreakpoint={767}>
          {this.getSidebar(showAds)}
        </Breakpoint>
        <div styleName="latest-news">
          <LatestPosts compact/>
          {showAds && <Breakpoint minBreakpoint={767}>
            <div styleName="native-ads">
              <GAMNativeAdHolder grow={true} type={2}/>
            </div>
          </Breakpoint>}
          <MagShortCut isTheme/>
        </div>
        <RandomArticle/>
        </Fragment>
    )
  }

  sidebarAds (AdElement) {
    return <Fragment>
      <AdsForDesktop>
        <AdElement debugName="article-sidebar" fallBackElements={[NoAdBlocker, Newsletter]}/>
      </AdsForDesktop>
      <AdsForMobile>
        <AdSlotMob2 fallBackElements={[NoAdBlocker, Newsletter]}/>
      </AdsForMobile>
    </Fragment>
  }

  getSidebar (showAds, className = '', view) {
    return (
      <aside styleName={`sidebar ${className}`}>
        <Sidebar latestMag popular filteredArticles nativeAds doneLoading={() => this.props.doneLoading('sidebar')}>
          <div styleName="full-width">
            {showAds && this.sidebarAds(AdSlotRectangle1)}
          </div>
          <Breakpoint maxBreakpoint={breakpoints.sm}>
            <WhyOrder/>
          </Breakpoint>
          <EditorsPicks doneLoading={() => this.props.doneLoading('editorsPicks')}/>
          <CrossLinkBox doneLoading={() => this.props.doneLoading('crossLinks')}/>
          <Breakpoint minBreakpoint={breakpoints.sm}>
            <WhyOrder/>
          </Breakpoint>
          <div styleName="full-width">
            {showAds && this.sidebarAds(AdSlotSkyscraper1)}
          </div>
        </Sidebar>
      </aside>
    )
  }
}

export default withErrorBoundary(
  FlowArticle,
  ErrorPlaceholder()
)
